import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Typography
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withRouter } from 'react-router-dom'

import sharedStyles from '../assets/styles/shared.css'
import { AuthContext } from '../context'
import firebase from '../services/firebase'
import ls from 'local-storage'

const Layout = ({ children, history }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const userData = useContext(AuthContext)

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        setAnchorEl(null)
        ls.set('branch', '')
        history.push('/')
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('Unexpected error signing out', err)
      })
  }

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          {
            userData && (
              <IconButton
                aria-label='Menu'
                className={sharedStyles.menuButton}
                color='inherit'
                onClick={handleMenu}
                style={{
                  paddingLeft: '0',
                  color: 'white'
                }}
              >
                <MenuIcon />
              </IconButton>

            )
          }
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            id='menu-appbar'
            keepMounted
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          {
            userData &&
            <span className={sharedStyles.toolbarButtons}>
              <Typography color='inherit' variant='h5'>
                {userData.email}
              </Typography>
            </span>
          }

        </Toolbar>
      </AppBar>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(Layout)
