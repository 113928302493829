import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import theme from '../theme'

// We can inject some CSS into the DOM.
const styles = {
  root: {
    background: theme.secondary,
    borderRadius: 27,
    border: 0,
    color: 'black',
    height: 48,
    padding: '0 30px',
    textTransform: 'capitalize'
  }
}

function StyledButton (props) {
  const { classes, children, className, ...other } = props

  return (
    <Button className={clsx(classes.root, className)} {...other}>
      {children || 'class names'}
    </Button>
  )
}

StyledButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default withStyles(styles)(StyledButton)
