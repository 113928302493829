/* eslint-disable prefer-promise-reject-errors */
import React from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  TextField,
  Container,
  Button
} from '@material-ui/core'
import Layout from '../components/Layout'
import SearchIcon from '@material-ui/icons/Search'
import theme from '../theme'
import { usePromiseAction } from '../lib/hooks'
import sharedStyles from '../assets/styles/shared.css'

const FindClient = ({ history }) => {
  const {
    error,
    inProgress
  } = usePromiseAction()

  return (
    <Layout>
      <Container>
        <form
          noValidate
          onSubmit={
            event => {
              event.preventDefault()
              const clientId = document.getElementById('clientName').value
              history.push(`/find/product/${clientId}`)
            }
          }>
          <TextField
            error={Boolean(error)}
            fullWidth
            helperText={error}
            id='clientName'
            label='Client Name or Account Number'
            name='clientName'
            required
            style={{
              marginTop: theme.spacing(3)
            }}
            type='text'
            variant='outlined'
          />

          <Button
            className={sharedStyles.customButton}
            color='secondary'
            disabled={inProgress}
            fullWidth
            style={{
              marginTop: theme.spacing(3)
            }}
            type='submit'
            variant='contained'
          >
            {
              inProgress
                ? <CircularProgress color='inherit' />
                : (
                  <>
                    <SearchIcon />
                    <span style={{ marginLeft: theme.spacing(2) }}>Find Client</span>
                  </>
                )
            }
          </Button>
        </form>
      </Container>
    </Layout>
  )
}

FindClient.propTypes = {
  history: PropTypes.object.isRequired
}

export default FindClient
