import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'

import theme from './theme'
import firebase from './services/firebase'

// Pages
import FindClient from './containers/FindClient'
import FindProduct from './containers/FindProducts'
import Login from './containers/Login'
import Product from './containers/Product'

// Import global styles
import './assets/styles/base.css'
import config from './config'
import { AuthContext } from './context'
import Find from './containers/Find'

const AppRoutes = () => {
  return (
    <>
      <Route
        component={Find}
        exact
        path='/find' />

      <Route
        component={FindClient}
        exact
        path='/find/client' />

      <Route
        component={FindProduct}
        exact
        path='/find/product/:clientID/:clientName' />

      <Route
        component={Product}
        exact
        path='/product/:productID' />
    </>
  )
}

const Root = () => {
  // Make the app wait for Firebase to be initialized before rendering the routes
  const [loggedIn, setLoggedIn] = useState(false)
  // Show an error if for example Firebase initialization fails
  const [isErrored, setIsErrored] = useState(false)
  // Set and show user
  const [user, setUser] = useState(null)

  useEffect(() => {
    try {
      // Initialize application
      firebase.initializeApp(config.firebase.config)

      // Initialize performance monitoring
      firebase.performance()

      firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          setLoggedIn(true)
          setUser(authUser)
          return
        }

        setLoggedIn(false)
        setUser(null)
      })
    } catch (error) {
      setIsErrored(true)
    }
  }, [])

  if (isErrored) {
    return (
      <div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '20px'
      }}>
        <h3>Error loading page</h3>

        {
          process.env.NODE_ENV === 'development' && (
            <p>
              Ensure the Firebase credentials in your `.env` are present and correct
            </p>
          )
        }
      </div>
    )
  }

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={user}>
          <Router>
            <Switch>
              <Route
                component={Login}
                exact={loggedIn}
                path='/'
              />

              {
                loggedIn && <AppRoutes />
              }
            </Switch>
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('app'))
