import { createMuiTheme } from '@material-ui/core/styles'

// Add custom theme values here. See https://material-ui.com/customization/themes/
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#02aeef'
    },
    secondary: {
      main: '#ffd600'
    }
  },
  shadows: [
    'none'
  ]
})

export default theme
