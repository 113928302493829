/* eslint-disable no-undef */
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import {
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Snackbar,
  Slide,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import {
  VisibilityOff,
  Visibility
} from '@material-ui/icons'
import ls from 'local-storage'
import { AuthContext } from '../context'
import firebase from '../services/firebase'

import theme from '../theme'
import sharedStyles from '../assets/styles/shared.css'
import Logo from '../assets/images/boltfast-logo.svg'
import StyledButton from '../components/StyledButton'

const Login = ({ history }) => {
  // Show/hide password
  const [showPassword, setShowPassword] = useState(false)
  // Input errors
  const [errors, setErrors] = useState({
    email: false,
    message: '',
    password: false,
    branch: false
  })

  const userData = useContext(AuthContext)

  const [selectedBranch, setSelectedBranch] = useState('')
  const onBranchChange = event => {
    setSelectedBranch(event.target.value)
  }

  const handleLogin = event => {
    event.preventDefault()

    const email = document.getElementsByName('email')[0].value
    const password = document.getElementsByName('password')[0].value

    if (!email || !password || !selectedBranch) {
      setErrors({
        email: !email,
        password: !password,
        branch: !selectedBranch
      })
    }

    if (selectedBranch !== '') {
      firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          ls.set('branch', selectedBranch)
          history.push(`/find`)
        })
        .catch((err) => {
          setErrors({
            ...errors,
            message: err.message
          })
        })
    } else {
      setErrors({
        ...errors,
        message: 'Please select a branch'
      })
    }
  }

  const closeSnackbar = () => {
    setErrors({
      ...errors,
      message: ''
    })
  }

  if (userData && window.location.pathname === '/') {
    return <Redirect to='/find' />
  }

  return (
    <Container
      maxWidth='md'
      style={{
        paddingLeft: 0,
        paddingRight: 0
      }}>
      <Grid container>
        <img alt='Boltfast'
          className={sharedStyles.logo}
          src={Logo}
          style={{
            marginBottom: theme.spacing(10),
            marginTop: theme.spacing(10)
          }} />
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: 'lightgrey',
          marginBottom: theme.spacing(10),
          padding: theme.spacing(2)
        }}>
        <form
          noValidate
          onSubmit={handleLogin}
          style={{ margin: '0 auto' }}
        >
          <FormControl
            fullWidth
            variant='outlined'
          >
            <InputLabel htmlFor='branch-select'>Branch</InputLabel>
            <Select
              className={sharedStyles.customInput}
              error={errors.branch}
              fullWidth
              id='branch-select'
              label='Branch'
              labelId='branch-select-label'
              onChange={onBranchChange}
              placeholder='Branch'
              required
              style={{
                marginTop: theme.spacing(2)
              }}
              value={selectedBranch}
              variant='outlined'
            >
              <MenuItem value={`C`}>Cape Town</MenuItem>
            </Select>
            <FormHelperText error={errors.branch}>{errors.branch && 'Please select a branch'}</FormHelperText>
          </FormControl>

          <Typography
            className={sharedStyles.customTitle}
            style={{
              marginTop: theme.spacing(1)
            }}
            variant='h6'
          >
            Please enter your login details to proceed
          </Typography>

          <TextField
            className={sharedStyles.customInput}
            error={errors.email}
            fullWidth
            helperText={errors.email && 'Email is required'}
            id='email'
            label='Email address'
            name='email'
            required
            style={{
              marginTop: theme.spacing(2)
            }}
            type='email'
            variant='outlined'
          />

          <TextField
            className={sharedStyles.customInput}
            error={errors.password}
            fullWidth
            helperText={errors.password && 'Password is required'}
            // eslint-disable-next-line react/jsx-sort-props
            id='password'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='Toggle password visibility'
                    edge='end'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            label='Password'
            name='password'
            required
            style={{
              marginTop: theme.spacing(2)
            }}
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
          />

          <Snackbar
            autoHideDuration={6000}
            // eslint-disable-next-line react/jsx-sort-props
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
            message={<span id='message-id'>{errors.message}</span>}
            onClose={closeSnackbar}
            open={Boolean(errors.message)}
            // eslint-disable-next-line react/jsx-sort-props
            TransitionComponent={(props) => (
              <Slide {...props} direction='left' />
            )}
            variant='error'
          />

          <StyledButton
            className={sharedStyles.customButton}
            color='secondary'
            fullWidth
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(5)
            }}
            type='submit'
            variant='contained'
          >
            Login
          </StyledButton>
        </form>
      </Grid>
      <div style={{ margin: '0 auto' }}>
        <p>Testing details:</p>
        <p>username: <code>testing@deimos.co.za</code></p>
        <p>password: <code>admin123!</code></p>
      </div>
    </Container>
  )
}

Login.propTypes = {
  history: PropTypes.object.isRequired
}

export default Login
