/* eslint-disable prefer-promise-reject-errors */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  TextField,
  CircularProgress,
  Typography
} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import reqClient from '../lib/reqClient'
import theme from '../theme'
import Layout from '../components/Layout'
import { usePromiseAction } from '../lib/hooks'
import StyledButton from '../components/StyledButton'
import ls from 'local-storage'

const FindProducts = props => {
  const { history } = props
  const { clientID, clientName } = props.match.params

  const {
    doAction,
    error,
    inProgress
  } = usePromiseAction()

  const handleProductSearch = event => {
    event.preventDefault()

    return new Promise((resolve, reject) => {
      const productId = document.getElementById('productId').value
      const clientId = document.getElementById('clientId').value
      const branchCode = ls.get('branch')

      if (!productId) {
        reject('Product ID is required.')
        return
      }

      reqClient
        .get(`productSearchByID?cust_no=${clientId}&product_id=${productId}&branch_code=${branchCode}`)
        .then(response => {
          if (response && response.data.data && Object.keys(response.data.data).length) {
            return resolve(response)
          }

          reject(`Product with ID - "${productId}" - not found.`)
        })
        .catch(() => reject(`No recommended price found for ID ${productId}. Please refer to Boltpack.`))
    })
  }

  return (
    <Layout>
      <Container>
        <Typography
          color='inherit'
          style={{
            marginTop: theme.spacing(3)
          }}
          variant='h5'
        >
          {clientName}
        </Typography>
        <Typography
          color='inherit'
          variant='subtitle1'
        >
          {clientID}
        </Typography>
        <form
          noValidate
          onSubmit={event => doAction(
            () => handleProductSearch(event),
            (response) => {
              const product = response.data.data
              history.push(`/product/${product.product_id}?clientId=${clientID}&clientName=${clientName}&initialPrice=${product.suggested_price}&description=${encodeURIComponent(product.description)}`)
            }
          )}>
          <TextField
            error={Boolean(error)}
            fullWidth
            helperText={error}
            id='productId'
            label='Product ID'
            margin='normal'
            required
            style={{
              marginTop: theme.spacing(4)
            }}
            variant='outlined'
          />
          <input id='clientId' type='hidden' value={clientID} />
          <StyledButton
            color='secondary'
            disabled={inProgress}
            fullWidth
            style={{
              marginTop: theme.spacing(3)
            }}
            type='submit'
            variant='contained'
          >
            {
              inProgress
                ? <CircularProgress color='inherit' />
                : <span>Find Product</span>
            }
          </StyledButton>
        </form>
        <Link
          component='button'
          onClick={() => {
            history.push('/find')
          }}
          style={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
          }}
          variant='body2'
        >
          Start a new search
        </Link>
      </Container>
    </Layout>
  )
}

FindProducts.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default FindProducts
