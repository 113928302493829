import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  TextField,
  Container
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import sharedStyles from '../assets/styles/shared.css'
import theme from '../theme'
import Layout from '../components/Layout'
import StyledButton from '../components/StyledButton'

const Product = ({ location, match, history }) => {
  const matches = useMediaQuery('(max-width:420px)')
  const queryParams = location.search && new URLSearchParams(location.search)

  const { productID } = match.params
  const clientID = queryParams.get('clientId')
  const clientName = queryParams.get('clientName')
  const description = decodeURIComponent(queryParams.get('description'))
  const initialPrice = parseFloat(queryParams.get('initialPrice'))

  // Number of products
  const [units, setUnits] = useState(1)

  return (
    <Layout>
      <Container>
        <Typography
          color='inherit'
          style={{
            marginTop: theme.spacing(3)
          }}
          variant='subtitle1'
        >
          {`${clientName} / ${clientID}`}
        </Typography>

        <Typography
          style={{
            marginTop: theme.spacing(3)
          }}
          variant='h5'
        >
          {description}
        </Typography>
        <Typography
          color='inherit'
          variant='h6'
        >
          {productID}
        </Typography>

        <form
          noValidate
          onSubmit={event => {
            event.preventDefault()
          }}
          style={{
            marginTop: theme.spacing(3)
          }}
        >
          <div className={`${sharedStyles.indicatorGreen}`}>
            <TextField
              disabled
              fullWidth
              label='Recommended Price'
              name='clientName'
              required
              type='text'
              value={initialPrice.toLocaleString()}
              variant='filled'
            />
          </div>

          <TextField
            fullWidth
            label='Units'
            onChange={event => setUnits(event.target.value)}
            required
            style={{
              marginTop: theme.spacing(2)
            }}
            type='number'
            value={units}
            variant='outlined'
          />

          <TextField
            disabled
            fullWidth
            id='clientName'
            label='Total'
            name='clientName'
            required
            style={{
              marginTop: theme.spacing(2)
            }}
            type='text'
            value={(parseInt(units) * initialPrice).toLocaleString()}
            variant='outlined'
          />

          <StyledButton
            color='secondary'
            disabled
            fullWidth
            style={{
              marginTop: theme.spacing(3)
            }}
            type='submit'
            variant='contained'
          >
            Add to Order
          </StyledButton>
        </form>

        <StyledButton
          color='secondary'
          fullWidth={matches}
          onClick={() => {
            history.goBack()
          }}
          style={{
            marginTop: !matches ? theme.spacing(3) : theme.spacing(5),
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
          }}
          variant='contained'
        >
          Search for another product
        </StyledButton>

        <StyledButton
          color='secondary'
          fullWidth={matches}
          onClick={() => {
            history.push('/find')
          }}
          style={{
            marginTop: !matches ? theme.spacing(3) : 0,
            marginLeft: !matches ? theme.spacing(3) : 0,
            marginBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
          }}
          variant='contained'
        >
          Start a new search
        </StyledButton>
      </Container>
    </Layout>
  )
}

Product.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Product
