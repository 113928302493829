import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import StyledButton from '../components/StyledButton'
import { usePromiseAction } from '../lib/hooks'
import theme from '../theme'
import {
  CircularProgress,
  TextField,
  Container
} from '@material-ui/core'
import reqClient from '../lib/reqClient'
import Divider from '@material-ui/core/Divider'
import sharedStyles from '../assets/styles/shared.css'

const handleFindClient = (event) => {
  event.preventDefault()

  return new Promise((resolve, reject) => {
    const clientName = document.getElementById('clientName').value

    if (!clientName) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Client name is required.')
      return
    }

    reqClient
      .get(`client-search?q=${clientName}`)
      .then(response => {
        const clientData = response && response.data
        if (clientData.data && clientData.data.product_id) {
          resolve(response)
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('No client with the provided Name or Account Number could be found')
        }
      })
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(() => reject('No client with the provided Name or Account Number could be found'))
  })
}

const Find = ({ history }) => {
  const {
    doAction,
    error,
    inProgress
  } = usePromiseAction()
  return (
    <Layout>
      <Container>
        <form
          noValidate
          onSubmit={event => doAction(
            () => handleFindClient(event),
            (response) => {
              const clientData = response && response.data
              history.push(`/find/product/${clientData.data.client_id}/${clientData.data.name}`)
            }
          )}
        >
          <TextField
            error={Boolean(error)}
            fullWidth
            helperText={error}
            id='clientName'
            label='Client Name or Account Number'
            name='clientName'
            required
            style={{
              marginTop: theme.spacing(5)
            }}
            type='text'
            variant='outlined'
          />

          <StyledButton
            className={sharedStyles.customButton}
            color='secondary'
            disabled={inProgress}
            fullWidth
            style={{
              marginTop: theme.spacing(3)
            }}
            type='submit'
            variant='contained'
          >
            {
              inProgress
                ? <CircularProgress color='inherit' />
                : <span>Find Client</span>
            }
          </StyledButton>
        </form>

        <Divider style={{
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(5)
        }} />

        <StyledButton
          className={sharedStyles.customButton}
          color='secondary'
          disabled
          fullWidth
          type='submit'
          variant='contained'
        >
          <span>Get Quote Estimate</span>
        </StyledButton>
      </Container>
    </Layout>
  )
}

Find.propTypes = {
  history: PropTypes.object.isRequired
}

export default Find
