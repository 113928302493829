/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react'

/**
 * Wraps a promise to provide some useful behaviours (the promise is invoked immediately)
 * Useful for example - loading data
 * @param {Promise<>} prom Promise item
 * @param {Array<any>} deps [Optional] Dependencies - when the values in the deps array changes, the promise is rerun
 */
export const usePromise = (prom, successCallback, deps = []) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    prom()
      .then((result) => {
        successCallback(result)
        setLoading(false)

        return result
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
      })
  }, [prom, successCallback])

  return {
    error,
    loading

  }
}

/**
 * Similar to `usePromise` but the promise isn't done until it is manually triggered
 */
export const usePromiseAction = () => {
  const [error, setError] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [response, setResponse] = useState()

  return {
    doAction (prom, successCallback) {
      // Reset values
      setError('')
      setInProgress(true)
      setResponse(null)

      prom()
        .then((result) => {
          setInProgress(false)
          setResponse(result)

          successCallback(result)
        })
        .catch((err) => {
          setInProgress(false)
          setError(err)
        })
    },
    error,
    inProgress,
    response
  }
}
